<template>
  <el-row :gutter="40">
    <el-col :xs="24" :sm="12" :md="12" :lg="6">
      <div class="statistic-item card-shadow">
        <div class="statistic-item__image statistic-item__image--green">
          <i class="el-icon-user-solid"></i>
        </div>
        <div class="statistic-item__content">
          <div class="statistic-item__content__title">New Visits</div>
          <count-to
            :start-val="0"
            :end-val="102400"
            :duration="3000"
            class="statistic-item__content__number"
          />
        </div>
      </div>
    </el-col>
    <el-col :xs="24" :sm="12" :md="12" :lg="6">
      <div class="statistic-item card-shadow">
        <div class="statistic-item__image statistic-item__image--blue">
          <i class="el-icon-s-comment"></i>
        </div>
        <div class="statistic-item__content">
          <div class="statistic-item__content__title">Messages</div>
          <count-to
            :start-val="0"
            :end-val="81212"
            :duration="3000"
            class="statistic-item__content__number"
          />
        </div>
      </div>
    </el-col>
    <el-col :xs="24" :sm="12" :md="12" :lg="6">
      <div class="statistic-item card-shadow">
        <div class="statistic-item__image statistic-item__image--red">
          <i class="el-icon-s-shop"></i>
        </div>
        <div class="statistic-item__content">
          <div class="statistic-item__content__title">Purchases</div>
          <count-to
            :start-val="0"
            :end-val="13600"
            :duration="3000"
            class="statistic-item__content__number"
          />
        </div>
      </div>
    </el-col>
    <el-col :xs="24" :sm="12" :md="12" :lg="6">
      <div class="statistic-item card-shadow">
        <div class="statistic-item__image statistic-item__image--olive">
          <i class="el-icon-s-order"></i>
        </div>
        <div class="statistic-item__content">
          <div class="statistic-item__content__title">Order</div>
          <count-to
            :start-val="0"
            :end-val="81212"
            :duration="3000"
            class="statistic-item__content__number"
          />
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import CountTo from 'vue-count-to'
export default {
  components: {
    CountTo,
  },
}
</script>

<style lang="scss">
.statistic-item {
  box-sizing: border-box;
  display: flex;
  height: 108px;
  background: #fff;
  align-items: stretch;
  padding: 16px 24px;
  cursor: pointer;
  @media (max-width: 1199px) {
    margin-bottom: 12px;
  }
  &__image {
    transition: all 0.38s ease-out;
    font-size: 60px;
    width: 76px;
    height: 76px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    &--green {
      color: #40c9c6;
    }
    &--blue {
      color: #36a3f7;
    }
    &--red {
      color: #f4516c;
    }
    &--olive {
      color: #34bfa3;
    }
  }
  &:hover {
    .statistic-item__image {
      color: #ffffff;
      &--green {
        background-color: #40c9c6;
      }
      &--blue {
        background-color: #36a3f7;
      }
      &--red {
        background-color: #f4516c;
      }
      &--olive {
        background-color: #34bfa3;
      }
    }
  }
  &__content {
    flex: 1;
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: bold;
    &__title {
      color: rgba(0, 0, 0, 0.45);
      font-size: 16px;
    }
    &__number {
      margin-top: 8px;
      font-size: 20px;
      color: #666666;
    }
  }
}
</style>
